/*@import url('base.css');*/


html:lang(lt) .lang-en,
html:lang(lt) *:lang(en)
{
    display: none;
}

html:lang(en) .lang-lt,
html:lang(en) *:lang(lt)
{
    display: none;
}


h5.date, h5.location, h5.date-location {
    color: #f6c97b;
}

h5.date-location {
    margin-top: 1em;
}



/** PARRALAX **/
.parralax {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 1px;
    scroll-behavior: smooth;
}
/*
.parralax div {
        position: absolute;
        top: 0;        right: 0;        bottom: 0;        left: 0;
}

.layer1 {   transform: translateZ(0); }
.layer2 {   transform: translateZ(-1px); }
.layer3 {   transform: translateZ(-2px); } */


/** TOP **/
header.top, header.top div.top-inner, header.top::after, header.top div.top-shadow, header.top div.top-inner::before {
    height: 220px;
}

header.top {
    width: 100%;
    position: sticky; 
    top: 0px; 
    /*xtransform: translateY(calc(var(--scrollPosition) / -20));*/
    transform: translateY(var(--scrollPosTop));
    transition: all .2s ease-in-out;

    z-index: 10;
}

header.top:hover {
    transform: translateY(0);
}

/* zvaigzdetas */
header.top div.top-inner {
    position: relative;
    width: 100%;
    text-align: center; 
    box-sizing: border-box;
    overflow: hidden;
    /*xbackground-color: tomato; xbackground-color: rgba(255, 99, 71, .5);*/
    
    background-color: #1f1d1b;
    background-image: url(../images/starry-2560-50-kai-pilger-Ef6iL87-vOA-unsplash.webp);
    background-repeat: repeat;    background-position: center;    background-size: auto;     background-origin: border-box;
        
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 90 100 90'><path d='m 0 90 v 0 h 100 v 90 q -50 -15 -100 0' fill='%23000000'/></svg>");
    mask-clip: border-box;    mask-repeat: no-repeat;    mask-origin: border-box;
    mask-position: bottom center;
    mask-size: max(100%, calc(1024px + 75%)) 1024px;
    
    z-index: 12;
    pointer-events: none;

    xbackground-position:  calc(var(--scrollPosition) / -125) calc(var(--scrollPosition) / -200); /* star field movement */
    background-position:  calc(var(--scrollPosition) / -1200) calc(var(--scrollPosition) / -200); /* star field movement */
}

/* rausvas lankas */
header.top::after {
    content:'';
    width: 100%;
    xheight: 240px;
    position: absolute;
    top: 0;
    xbackground-color: rgb(255,99,71,.4);
    background-color: rgb(255,99,71,.35);
    xbackground-color: rgba(170,45,33,.5);
    xbackground-color: rgba(94,58,42,.6);
    xbackground-color: rgba(0,0,0,.5); 
    
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 85 100 5'><path d='m 0 90 q 50 -10 100 0 q -50 -8.5 -100 0' fill='%23000000'/></svg>");
    mask-clip: border-box;    mask-repeat: no-repeat;    mask-origin: border-box;
    mask-position: center bottom;
    mask-size: max(100%, calc(1024px + 75%)) 100px;

    z-index: 13;
    pointer-events: none;
}

/* šešėlis */
header.top div.top-shadow {
    content:'';
    width: 100%;
    xheight: 480px;
    position: absolute;
    top: 0;
    background-color: rgba(0,0,0,.5);
    
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 90 100 90'><path d='m 0 90 v 0 h 100 v 90 q -50 -12 -100 0' fill='%23000000'/></svg>");
    mask-clip: border-box;    mask-repeat: no-repeat;    mask-origin: border-box;
    mask-position: bottom center;
    mask-size: max(100%,calc(1024px + 75%)) 1024px;

    display: flex;
    justify-content: center;

    transition: all .2s ease-in-out;
    xcolor: rgba(255,255,255,0);
    
    xpointer-events: none;
}
header.top:hover div.top-shadow, header.top:focus div.top-shadow, header.top:active div.top-shadow, header.top.active div.top-shadow
{
    xheight: 480px;
    xheight: 520px;
    height: var(--top-shadow-height);
    xz-index: 11;
    background-color: rgba(0,0,0,.7);
    xpointer-events: auto;
    
}
/*header.top nav a { xz-index: 1000; }*/

header.top div.top-shadow nav {
    display:none;
    xtransition: all .2s ease-in-out;
}
header.top:hover div.top-shadow nav, header.top:focus div.top-shadow nav, header.top:active div.top-shadow nav, header.top.active div.top-shadow nav {
    display: block;
}

/** NAV **/
nav {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    margin-bottom: 100px;

}
nav li {
    display: block;
    list-style: none;
    text-align: center;
}

@media (min-width: 813px) and (orientation: portrait), (min-width: 769px) {
    nav li {
        display: inline-block;
    }
    h5.date-location {
        display: none;
    }
}

nav li > a {
    text-rendering: optimizeLegibility;
    /*font-family: "Red Hat Display", Helvetica, Arial, sans-serif;*/
    font-family: var(--head-font);
    text-transform: uppercase;
    line-height: 1.2em;
    font-weight: 300;
    letter-spacing: 2px;
    color: #f0f0f0 !important;
    font-size: 1.5em;
    border-radius: 14px;

    transform: scale(0.975);
    margin-bottom: 4px;

    text-decoration:none;
    cursor:pointer;
    transition: all .1s ease-in-out;
    padding: 12px 17px 12px 19px;
    display: inline-block;
    text-transform: uppercase;
    white-space: nowrap;
    outline: 0 !important;
    border: 1px solid transparent !important;
    z-index:100;
    position: relative;
}
nav li > a:hover {
    transform: scale(1);
    border: 1px solid #f0f0f0 !important;
    xbackground-color: rgba( 240, 240, 240, 0.05 ) !important;
    background-color: rgba( 0, 0, 0, 0.2 ) !important;
    box-shadow: inset rgba(255,255,255,.2) 0px 0px 20px -4px;
    xbox-shadow: inset #000 0px 1px 20px 0px; 
    position: relative;
    color: #fff !important;
}
nav li > a:active {
    -webkit-box-shadow:inset 0 1px 2px rgba(0,0,0,0.1);
    -moz-box-shadow:inset 0 1px 2px rgba(0,0,0,0.1);
    box-shadow:inset 0 1px 2px rgba(0,0,0,0.2);
}
nav li > a:focus {
    background-color: rgba( 240, 240, 240, 0.2 );
}


/* content */
header.top div.top-inner-2 {
    width: 100%;
    margin: 0 auto;
    height: 130px;
    display: flex; 
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    overflow: hidden;
    text-align: center;
}
header.top div.top-inner-2 a {
    display: inline-block;
    position: relative;
    z-index: 1123;
}


/* gradientai */
header.top div.top-inner::before {
    content:'';
    position: absolute;
    top:0px;
    width: 100%;
    xheight: 240px;
    display: block; 
    overflow: hidden;
    text-align: center;
    background-color: rgba(46, 23, 5, 0.5);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(46,23,5,.6) 40%, rgba(46,23,5,.6) 60%, rgba(0,0,0,1) 100%),
        /*radial-gradient(ellipse at center, rgba(255,99,71,.5) 0%,rgba(255,99,71,.2) 50%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 100%);*/
        radial-gradient(ellipse at center, rgba(236,47,47,0.2) 0%,rgba(255,0,0,.1) 50%,rgba(255,0,0,0) 75%,rgba(255,0,0,0) 100%);
        /*radial-gradient(ellipse at center, rgba(255,0,0,.1) 0%,rgba(255,0,0,.1) 35%,rgba(255,0,0,0) 65%,rgba(255,0,0,0) 100%);*/  
}



header.top div.top-inner-2 h5 {
    margin: 10px;
    width: 200px;
    opacity: .5;
    font-size: 18px;
}
@media only screen and (max-width: 812px) {
    header.top div.top-inner-2 h5 {
        display: none;
    }
}

@media only screen and (max-device-width: 812px) {
    header.top div.top-inner-2 h5 {
        display: none;
    }
}

/* rausvas lankas v1 border-radius */ /*
header.top div::after {
    content:'';
    xwidth: 120%;
    xwidth: max(120%, 1700px);
    xwidth: max(120%, 1440px);
    xwidth: max(120%, calc(340px + 96%));
    width: max(120%, calc(212.5px + 105%));
    height: 300px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    xleft: max(50%, 708px);
    xleft: max(50%, 600px);
    xleft: max(50%, calc(142px + 40%));
    left: max(50%, calc(88.5px + 43.75%));
    margin-top: 15px;
    xmargin-left: -60%;
    xmargin-left: min(-60%, calc(-850px ));
    xmargin-left: min(-60%, calc(-850px - (1416px - 100%) / 2));
    xmargin-left: min(-60%, calc(-720px - (1200px - 100%) / 2));
    xmargin-left: min(-60%, calc(-850px + (1416px - 100%) / 2.7)); 
    margin-left: min(-60%, calc(-850px + (1416px - 100%) / 2.22));
    xbackground-color: tomato;
    background-color: rgba(170,45,33,.5);
    xbackground-color: rgba(94,58,42,.6);
    z-index: 10;
}*/

/* scroll tracker debug */ /*
header.top>div::after {
    content: var(--scrollPositionText);
    color: white;
    font-size: 48px;
    z-index: 1001;
    position: absolute;
    top:0;
} */




section {
    position: relative;
    width: 100%; 
    min-height: max(100vh,500px);
    /*min-height: -webkit-fill-available;*/
    text-align: center; 
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    margin-top: -90px;

    display: flex; align-items: stretch; 
}

/*
@supports (height: 100dvh) {
    .xxx {
         min-height: calc(100svh - 6rem);
    }
}*/

.section_title {
    margin-top: 1em;
}

section .inner {
    position: relative;
    xdisplay: block; 
    width: 100%; 
    height: 100%;
    min-height: max(100vh,500px);
    padding: 40px 20px 160px 20px; /* XXX */
    margin: 0 0 -30px 0;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;

    /*mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 15 100 285'><path d='m 0 20 q 50 -10 100 0 v 280 h -100 z' fill='%23000000'/></svg>");*/
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 15 100 2850'><path d='m 0 20 q 50 -10 100 0 v 2845 h -100 z' fill='%23000000'/></svg>");
    mask-clip: border-box;    mask-repeat: no-repeat;    mask-origin: border-box;
    mask-position: top center;
    mask-size: max(100%, calc(1024px + 62.5%)) 50000px;

    
}
/*
.inner {
    xborder-radius: 100% 100% 0 0/90px 90px 0 0; 
    xxborder-radius: max(1280px,100vw) max(1280px,100vw) 0 0/180px 180px 0 0;
    xxwidth: 120%;
    width: 100%;
    height: 100vh;
    min-height: 400px;
    display: block; 
    margin: 0 -10% -30px -10%;
    margin: 0 0 -30px 0;
    position: relative;
    overflow: hidden;
    text-align: center;
    xxclip-path: ellipse(max(640px + 70%,140%) 60% at 50% 60%);
    box-sizing: border-box;

    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 15 100 285'><path d='m 0 20 q 50 -10 100 0 v 280 h -100 z' fill='%23000000'/></svg>");
    mask-clip: border-box;    mask-repeat: no-repeat;    mask-origin: border-box;
    mask-position: top center;
    xmask-size: max(100%, calc(1024px + 75%)) 5950px;
    xmask-size: max(100%, calc(1024px + 75%)) 6950px;
    mask-size: max(100%, calc(1024px + 62.5%)) 5000px;
}
*/

section .background-image {
    position: absolute;
    top: -150px;    right: -150px;    bottom: 0px;    left: -150px;
    overflow: hidden;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: 50% 0;
    transition: opacity 0.3s ease-out 0s;
    z-index: -5;
}

section .background-image::after {
    content: '';
    position: absolute;
    top: 0;    right: 0;    bottom: 0;    left: 0;
    /*xbackground-color: rgba(16, 8, 6, 0.8);
    xbackground-color: rgba(46, 23, 5, 0.5);*/
    background-color:  rgba(20, 10, 2, 0.8);
}

section article {
    display: block;

    min-width: 320px;
    max-width: 1080px;
    margin: 0 auto;
    xborder: 1px solid green;
    box-sizing: border-box;
    text-align: center;
    xbackground-color: #f0f0f0;
    xmargin-top: -240px;
    xpadding-top: 240px;
}
section article p {
    align-self: center;
    text-align: justify;
}
div.page img, article.page img { max-width: 100%; }

/** INTRO **/
section#intro {
    padding-top: 95px; 
    width: 100%; 
    text-align: center; 
    overflow: hidden;
    margin-top: -180px;
    margin-bottom: -85px;
    xxperspective: 10px;
    xxtransform-style: preserve-3d;
}
section#intro .inner {
    display: flex; align-items: stretch; 
    mask-image: none; /* remove arc mask */
}
section#intro .inner article {
    display: flex;
    align-items: center;
    flex-direction: column;
    xalign-items: stretch;
    justify-content: space-between;    
}
section#intro .inner article h3 {
    margin-left:auto; margin-right: auto;
}


/*section.centered {
    padding-top: 95px; 
    width: 100%; 
    text-align: center; 
    overflow: hidden;
    margin-top: -180px;
    margin-bottom: -85px;
}*/
section.centered, section.centered .inner {
    display: flex; 
    align-items: center; 
    mask-image: none; /* remove arc mask */
}
/*section.centered .inner article {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;    
}*/







/*
section#intro .inner::before {
    content:'';
    position: absolute;
    top: 0; left:0; right:0; bottom:0;
    background-color: #1f1d1b;
    background-image: url(../images/themazcal117-2560-50.webp);
    background-repeat: no-repeat; background-size: cover;
    background-position: center center;
    xtransform: translateX(calc(var(--scrollPosition) / 20)) translateY(calc(var(--scrollPosition) / 2));
    transform: translateY(calc(var(--scrollPosition) / 2));
    xxtransform: translateZ(-25px) perspective(1px) scale(1);
}*/

section#intro .background-image {
    background-color: #1f1d1b;
    background-image: url(../images/themazcal117-2560-50.webp);
    background-position: 50% 0%;
    transform: translateY(calc(var(--scrollPosition) / 2));
}
section#intro .background-image::after {
    background-color: transparent;
}








/** VIZIJA **/

section#vizija .inner {
    background-color: rgba(255,0,0,.1);
}

section#vizija .background-image {
    xoverflow: hidden;
    background-color: #1f1d1b;
    background-image: url(../images/themazcal25-2560-60.webp);
    background-position: top center;
    xxbackground-position:  calc(var(--scrollPosition) / -20) calc(var(--scrollPosition) / 20);
    xtransform: translateZ(0); 
    transform: translateX( calc(var(--scrollPosition) / -20));
    
}



/** BILIETAI **/

section#bilietai {
}
section#bilietai .background-image {
    background-image: url(../images/themazcal60-2560-60.webp);
    background-repeat: no-repeat;    background-size: cover;    background-position: center;

    transform: translateX( calc(var(--scrollPosition) / 40));
}
section#bilietai .background-image::after {
    background-color:  rgba(20, 10, 8, 0.8);
}



/** MOON **/
section#moon {
    padding-top: 0px; 
}
section#moon .inner {
    background-color: #222;
    background-image: url(../images/moon-hi-FqexeBWakAA9jrg.jpg);
    background-repeat: no-repeat;    background-size: cover;    background-position: 50% 0%;
    display: flex;
    padding-bottom: 40px;
    margin-bottom: 0px;
}
/*
section#moon .inner::after {
    content: '';
    position: absolute;
    top:-25%; left:-25%; right:-25%; bottom:-25%;
    z-index: -1;
    transform: rotate( var(--scrollPosition) * 1deg );
    transform: rotate(45deg);

    background-color: #222;
    background-image: url(../images/moon-hi-FqexeBWakAA9jrg.jpg);
    background-repeat: no-repeat;    background-size: cover;    background-position: center;

}*/







/** PAPER **/ /*
section#paper .inner {
    background-color: #222;
    background-image: url(../images/paper_texture-2560-25.jpg);
    background-repeat: no-repeat;    background-size: cover;    background-position: center;
}*/


/** LOGO LINE **/
img.logo {
    max-width: 90vw;
    width: 450px;
    margin: 25px 0px 10px 0px;
}
img.bauba {
    max-width: 30vw;
    margin: 10px 20px;
}
div.page img.bs, article.page img.bs {
    max-width: min(40px,10vw);
    margin: 57px 0px 0px 0px;
}

div.logo-line {
    width: 100%;
    display: flex;                  /* establish flex container */
    flex-direction: row;            /* default value; can be omitted */
    flex-wrap: nowrap;              /* default value; can be omitted */
    justify-content: center;
    xborder: 1px solid red;
    margin: 0 auto 40px auto;
    z-index: 1;
    position: relative;
}





/** SOCIAL **/
/* .social-round i { border-radius: 50%; clip-path: circle(45% at 49% 50%); } */
h3.social { 
    width: 100%;
    margin: 0 auto;
    xcolor:#5a4d42; 
    position: relative; 
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    xheight: 90px;
    margin-top: 0;
    margin-bottom:0px;
    font-size: 28px;
}
.social a {
    position: relative; 
    display: inline-block;
    padding: 5px;
    margin: 8px;
    transition: all .1s ease-in-out;
    transform: scale(0.95);
    text-decoration: none;
    border: none;
}

.social a:visited, .social a:link {
    color:#5a4d42; 
    color: #79664d;
    xcolor:#f6c97b;
    border: none;
}

.social a:active, .social a:hover {
    color:#fff; 
    transform: scale(1);
    border: none;
}


/*div.bottom { margin-top: 15%; }*/
h3.social.bottom {
    align-self: flex-end;
    margin-bottom: 0px;

}

x{
color: rgb(59, 112, 34);
}


/* image stuff */

img.right,img.left,img.center {
    border: 0;
}

img.right {
    float: right;
    margin: 0 0 2em 2em;
}

img.left {
    float: left;
    margin: 0 2em 2em 0;
}

img.center {
    margin: 2em auto;
    text-align: center;
    display: block;
}


iframe.youtube {
margin: 2em 0;
}


